<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Servicios a Cliente</h4>
            <div class="small text-muted">Adiministración de servicios a clientes</div>
          </b-col>
          
          <b-col sm="5">
            <b-button @click="add()" type="button" variant="primary" size="sm" class="btn-pill float-right ml-2" v-b-tooltip.hover title="Agregar Categoría">
              <i class="fa fa-plus"></i> Agregar
            </b-button>

            <b-col sm="12" class="d-none d-md-block" v-if="!table.mostrarFiltros">
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" @click="table.mostrarFiltros=true" v-b-tooltip.hover title="Mostrar filtros">
                <i class="fa fa-filter"></i> Mostrar Filtros
              </b-button>
            </b-col>
            <b-col sm="12" class="d-none d-md-block" v-else>
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" v-if="table.mostrarFiltros" @click="table.mostrarFiltros=false" v-b-tooltip.hover title="Ocultar filtros">
                <i class="fa fa-window-close"></i>
              </b-button>
            </b-col>
          </b-col>                    
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row v-if="table.mostrarFiltros">
          <b-col sm="3">
            <b-form-group>
              <b-input-group>
                <b-form-input type="text" size="sm" placeholder="Ingrese su busqueda" v-model="filter.name"></b-form-input>                
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col sm="4">
            <v-select :options="arr.filter.customers" v-model="filter.customers" placeholder="Clientes" :multiple="true" :select-on-tab="true"></v-select>
          </b-col>     
          <b-col sm="2">
            <v-select :options="arr.filter.status" v-model="filter.status" placeholder="Estado" :multiple="true" :select-on-tab="true"></v-select>
          </b-col>              
          <b-col sm="2">
            <b-button variant="outline-dark" @click="filterServicesCustomers()">
              <b-icon icon="filter" class="mr-1" style="font-size:15px;"></b-icon>              
              Filtrar
            </b-button>
          </b-col>                         
        </b-row>

        <b-row>
          <b-col>
            <b-table class="mb-0"
                    responsive="sm"
                    head-variant="dark"
                    :hover="true"
                    :small="true"
                    :fixed="true"
                    :items="table.items"
                    :fields="table.fields"
                    :filter="table.filter"
                    :current-page="table.currentPage"
                    :per-page="table.perPage" 
                    :busy="table.isBusy"   
                    selectable
                    select-mode="single"
                    @row-selected="onRowSelected"                                    
                    v-if="table.items.length || table.isBusy">                       

                <template v-slot:table-colgroup="scope">
                  <col
                    v-for="field in scope.fields"                    
                    :key="field.key"
                    :style="{ width: field.width }"
                  >
                </template> 

                <template v-slot:table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Cargando...</strong>
                  </div>
                </template>

                <template v-slot:row-details>
                  <b-row>
                    <b-col md="8">
                      <b-card no-body>
                        <b-table class="mb-0 crud-services-customers-table-services"
                                responsive
                                head-variant="dark"
                                :hover="true"
                                :small="true"
                                :fixed="false"
                                :items="tableServices.items"
                                :fields="tableServices.fields">  

                          <template v-slot:cell(services)="row">                            
                            {{row.item.service.name}}
                          </template>                

                          <template v-slot:cell(observations)="data">                      
                            <span v-if="data.item.observations">                                                    
                              <b-badge variant="dark" :id="'popover-observations-' + data.item.id" class="popover-observations">
                                <b-icon icon="card-heading"></b-icon> 
                              </b-badge>
                              <b-popover :target="'popover-observations-' + data.item.id" variant="default" triggers="hover">                            
                                <div v-html="data.item.observations"></div>
                              </b-popover>                                                    
                            </span>                          
                          </template>

                          <template v-slot:cell(date_init)="row">  
                            {{moment(row.item.date_init).format('DD/MM/YYYY')}}
                          </template>  

                          <template v-slot:cell(list)="data">     
                            <span v-if="data.item.price_list">
                              {{data.item.price_list.reference}}
                            </span>
                          </template>  

                          <template v-slot:cell(quantity)="row">                                  
                            <div v-if="row.item.service.mode=='hours'">
                              {{row.item.quantity}} {{row.item.service.mode}}
                            </div>                          
                          </template>                                                      

                          <template v-slot:cell(price)="row">                                                      
                            {{Intl.NumberFormat('es-AR',{style:'currency',currency:row.item.price_list.currency.code}).format(row.item.price)}}
                          </template>

                          <template v-slot:cell(date_renewal)="row">  
                            <div v-if="row.item.canceled">
                              <b-badge variant="danger">FINALIZADO</b-badge>
                              <div class="crud-services-customers-reasons-canceled">
                                <b-icon icon="exclamation-circle" 
                                        v-if="row.item.canceled" 
                                        class="h4 mb-0" 
                                        v-b-tooltip.hover 
                                        :title="row.item.reasons_canceled" />                           
                              </div>                       
                            </div>

                            <div v-if="!row.item.canceled" class="pl-2 pr-2">
                              <div v-if="daysForRenewal(row.item)<1"
                                  class="crud-services-vto-danger">                                 
                                  {{moment(row.item.date_renewal).format('DD/MM/YYYY')}} 
                                  (Vencido por {{daysVtoRenewal(row.item)}} día/s)
                              </div>
                              <div v-else>
                                <div v-if="row.item.date_renewal" 
                                    :class="[daysForRenewal(row.item)<5 ? 'crud-services-vto-warning' : 'crud-services-vto-success']">
                                  {{moment(row.item.date_renewal).format('DD/MM/YYYY')}} 
                                  <b>(<span v-html="daysForRenewal(row.item)"></span> día/s)</b>
                                </div>
                              </div>

                              <div v-if="row.item.project_id">
                                <b-link @click="goProject(row.item)"> 
                                  <b>
                                    <b-icon icon="list-task"></b-icon> Ver Proyecto
                                  </b>
                                </b-link>
                              </div>                              
                            </div>
                          </template>  

                        </b-table>
                      </b-card>
                    </b-col>
                  </b-row>
                </template>

                <template v-slot:cell(id)="data">
                  <b>{{data.item.id}}</b>
                </template>

                <template v-slot:cell(name)="data">
                  {{data.item.name}}
                </template>

                <template v-slot:cell(date_init)="data">
                  {{moment(data.item.date_init).format('DD/MM/YYYY')}}                  
                </template>      

                <template v-slot:cell(date_end)="data">
                  <div v-if="data.item.date_end">
                    {{moment(data.item.date_end).format('DD/MM/YYYY')}}                  
                  </div>
                </template>   

                <template v-slot:cell(customers)="data">                  
                  <div v-if="data.item.customer_id">             
                    <b-avatar :src="data.item.customer.image"
                              v-if="data.item.customer.image">
                    </b-avatar>
                    <b-avatar v-else 
                              icon="building"                                            
                              variant="dark">
                    </b-avatar>     
                    &nbsp;<b>{{data.item.customer.name}}</b>      
                    <b-icon icon="circle-fill" v-if="!data.item.customer.active" class="ml-2" variant="danger" v-b-tooltip.hover title="Cliente INACTIVO" />                           
                  </div>
                </template>                

                <template v-slot:cell(status)="data">
                  <div v-if="data.item.canceled">
                    <b-badge variant="danger">FINALIZADO</b-badge>
                  </div>
                  <div v-else>
                    <b-badge variant="success">ACTIVO</b-badge>
                  </div>
                </template>

                <template v-slot:cell(reasons)="data">
                  <div class="crud-services-customers-reasons-canceled">
                    <b-icon icon="exclamation-circle" 
                            v-if="data.item.canceled" 
                            class="h4 mb-0" 
                            v-b-tooltip.hover 
                            :title="data.item.reasons_canceled" />                           
                  </div>
                </template>

                <template v-slot:cell(f_action)="data">
                  <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">
                    <b-dropdown-item @click="openCancel(data.item)" v-if="!data.item.canceled">
                      <b-icon icon="dash-circle-fill"></b-icon> Cancelar Servicio 
                    </b-dropdown-item> 
                    <b-dropdown-item @click="restoreServoceCancel(data.item)" v-else>
                      <b-icon icon="arrow-bar-left"></b-icon> Restaurar Servicio 
                    </b-dropdown-item> 

                    <b-dropdown-header>Acciones</b-dropdown-header>  
                    <b-dropdown-item @click="edit(data.item)">
                      <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                    </b-dropdown-item>
                    <b-dropdown-item @click="remove(data.item)">
                      <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
            </b-table>
            <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
          </b-col>
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>

          <b-col>
            <nav>
              <b-pagination class="pull-right mb-0"
                            size="sm"
                            pills=""
                            :total-rows="getRowCount(table.items)"
                            :per-page="table.perPage"
                            v-model="table.currentPage" />
            </nav>
          </b-col>          
        </b-row>
      </b-card>

      <!-- ########################### -->
      <!-- #####     MODALES     ##### -->
      <!-- ########################### -->

      <!-- CRUD MAIN -->
      <b-modal v-model="modal.form.active"              
              header-bg-variant="dark"
              header-text-variant="white"
              size="lg"
              no-close-on-esc
              no-close-on-backdrop
              hide-header-close>
        <div slot="modal-header">
          {{this.modal.form.title}}  
        </div>

        <form-wizard  title=""
                      subtitle=""
                      nextButtonText="Siguiente"
                      backButtonText="Atrás"
                      finishButtonText="Generar"
                      color="#2f353a"
                      shape="tab"
                      @on-complete="save">

          <tab-content  title="Cliente" 
                        icon="fa fa-handshake-o" 
                        :before-change="servicesCustomersValidStepOne">          
            <b-row>
              <b-col md="6">
                <b-form-group label="Nombre">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.form.name"
                                required
                                placeholder="Ingresar un nombre">
                  </b-form-input>
                </b-form-group>
              </b-col>    

              <b-col md="6">
                <b-form-group label="Inicio">
                  <b-form-datepicker size="sm" v-model="crud.form.date_init" placeholder="Fecha" local="es"></b-form-datepicker>                      
                </b-form-group>            
              </b-col>    

              <b-col md="6">
                <FindObject render="search"
                            type="customers" 
                            @select-object="loadCustomers($event)" 
                            :valueID="crud.form.customer_id"
                            :where="conditionCustomersActive"/>           
              </b-col>    
              <b-col md="6">
                <FindObject render="search"
                            type="priceList" 
                            @select-object="loadPriceList($event)" 
                            :valueID="crud.form.price_list_id"
                            :key="crud.form.price_list_id"
                            :where="conditionPriceListActive"/>                 
              </b-col>  
              <b-col md="12">
                <b-form-group label="Observaciones">
                  <b-form-textarea                
                    v-model="crud.form.observations"
                    placeholder="Ingresar las observaciones..."
                    rows="3"
                    max-rows="6"
                  ></b-form-textarea>
                </b-form-group>
              </b-col>         
            </b-row>      
          </tab-content>
          <tab-content title="Servicios" icon="fa fa-rocket">
            <b-row>
              <b-col lg="12" md="12" sm="12">
                  <Detail :customer_id="crud.form.customer_id" 
                          :date_init="crud.form.date_init"
                          :details="crud.form.services"
                          @load-detail="crud.form.services = $event"
                          :isEdit="crud.form.isEdit"/>
              </b-col>
            </b-row>
          </tab-content>
        </form-wizard>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" 
                    class="mr-1" 
                    @click="close()">
            Salir
          </b-button>              
        </div>
      </b-modal>

      <!-- CRUD CANCEL -->
      <b-modal v-model="modal.cancel.active"
              header-bg-variant="dark"
              header-text-variant="white"
              size="md">
        <div slot="modal-header">
          {{this.modal.cancel.title}}
        </div>

        <b-row>
          <b-col lg="12">
            <b-form-group label="Fecha Finalización">                
              <b-form-datepicker size="sm" v-model="crud.cancel.date_end" placeholder="Fecha" local="es"></b-form-datepicker>                      
            </b-form-group>            
          </b-col>
          <b-col lg="12">
            <b-form-group label="Motivos de Cancelación">
              <b-form-textarea                
                v-model="crud.cancel.reasons_canceled"
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.cancel.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="saveCancel()">Aceptar</b-button>          
        </div>
      </b-modal>

    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import FindObject from '@/components/inc/find/findObject'
  import Detail from '@/components/inc/cpbtDetail/detailServices'  
  import Param from '@/config/parameters'
  import Storage from '@/handler/storageLocal'
  import moment from 'moment'
  import {FormWizard, TabContent} from 'vue-form-wizard'
  import 'vue-form-wizard/dist/vue-form-wizard.min.css'

  export default {
    components: {
      FindObject,
      FormWizard,
      TabContent,
      Detail,       
    },      
    data: () => {
      return {      
        access: {
          module_id: Modules.SERVICIOS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'crudServicesCustomers',
          elements: {}
        }, 
        table : {
          items: [],
          fields: [
            {key: 'id', label: 'ID', sortable: true, class:"align-middle text-left", width:"50px"},
            {key: 'customers', label: 'Cliente', class:"align-middle text-left", width:"200px"},
            {key: 'name', label: 'Nombre', class:"align-middle text-left", width:"150px"},            
            {key: 'date_init', label: 'Inicio', class:"align-middle text-center", width:"100px"},                        
            {key: 'date_end', label: 'Fin', class:"align-middle text-center", width:"100px"},                        
            {key: 'status', label: 'Estado', class:"align-middle text-center", width:"100px"},                        
            {key: 'reasons', label: '', class:"align-middle text-center", width:"50px"},                        
            {key: 'f_action', label:'', width:"100px"},
          ],
          filter: null,
          mostrarFiltros: true,
          currentPage: 1,
          perPage: 50,
          isBusy: false,
        },  
        tableServices : {
          items: [],
          fields: [
            {key: 'services', label: 'Servicio', class:"align-middle", width:"150px"},            
            {key: 'observations', label: '', class:"align-middle", width:"25px"},            
            {key: 'date_init', label: 'Inicio', class:"align-middle text-center", width:"100px"},            
            {key: 'list', label: 'Lista', class:"align-middle text-center", width:"80px"},
            {key: 'quantity', label: 'Cantidad', class:"align-middle text-center", width:"100px"},            
            {key: 'price', label: 'Importe', class:"align-middle text-right", width:"100px"},     
            {key: 'date_renewal', label: 'Renovación', class:"align-middle text-center", width:"100px"},
          ]
        },                 
        crud: {
          form: {
            id: 0,
            name: '',
            date_init: '',
            observations: '',            
            customer_id: 0,
            customer: null,
            services: [],
            isEdit: false,
            price_list_id: 0,
            price_list: null,
            canceled: false,
          }, 
          cancel: {
            id: 0,
            reasons_canceled: '',
            date_end: ''
          }          
        },            
        modal: {
          form: {
            active: false,
            title: ''
          },   
          cancel: {
            active: false,
            title: ''
          },                       
        },  
        arr: {
          servicesCustomers: [],
          filter : {
            customers: [],
            status: [
              {code:false, label: 'Activo'},
              {code:true, label: 'Finalizado'},                
            ],
          }
        },
        filter: {
          name: '',
          customers: [],
          status: [
            {code:false, label: 'Activo'}
          ],
        },                                  
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },  
    mounted() {     
      this.filterLoad() 
      this.filterServicesCustomers()
    },
    computed: {
      conditionCustomersActive(){
        return [{field: 'active', condition: true}];
      },
      conditionPriceListActive(){
        return [{field: 'active', condition: true}];
      },       
    },       
    methods: {
      onRowSelected(item) {
        this.arr.servicesCustomers.forEach((element, index) => {          
          if(Array.isArray(item)) {
            if(item.length){
              if(element.id == item[0].id) {
                this.openDetail(index)
              }
            }
          } else {
            if(element.id == item.id) {
              this.openDetail(index)
            }            
          }          
        })
      },
      openDetail(index) {
        this.table.rowSelected = index
        this.filterServicesCustomers()
      },

      getRowCount (items) {
        return items.length
      },    
      load() {
        var result = serviceAPI.obtenerServicesCustomers()

        result.then((response) => {
          var data = response.data
          this.table.items = data   
          
          this.table.isBusy = false
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error))
        });        
      },
      add() {
        this.crud.form.id = 0
        this.crud.form.name = ''
        this.crud.form.date_init = ''
        this.crud.form.observations = ''
        this.crud.form.customer_id = 0
        this.crud.form.customer = null   
        this.crud.form.canceled = false   
        this.crud.form.isEdit = false        
        
        this.modal.form.title = "Nuevo Servicios a Cliente"
        this.modal.form.active = true
      },
      edit(item) {                
        this.crud.form.id = item.id
        this.crud.form.name = item.name
        this.crud.form.date_init = item.date_init
        this.crud.form.observations = item.observations
        this.crud.form.customer_id = item.customer_id
        this.crud.form.customer = item.customer     
        this.crud.form.canceled = item.canceled
        this.crud.form.isEdit = true

        this.crud.form.services = item.details         

        this.modal.form.title = "Editar Servicios de Cliente"
        this.modal.form.active = true
      },
      remove(item) {
        this.crud.form.id = item.id
        this.crud.form.name = item.name        

        this.$bvModal.msgBoxConfirm('¿Desea borrar el item (' + this.crud.form.id + ') - '+ this.crud.form.name + '?', {
          title: 'Borrar Servicios',
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.eliminarServicesCustomers(this.crud.form.id);

            result.then((response) => {      
              this.modal.form.active = false        
              loader.hide()
              this.filterServicesCustomers()
              this.$awn.success("Registro eliminado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },
      save() {
        this.$bvModal.msgBoxConfirm('¿Desea continuar con el alta de servicio a cliente?', {
          title: 'Alta Servicio',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'dark',
          okTitle: 'GENERAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: 'dark',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {                       
            let loader = this.$loading.show();            
            if (this.crud.form.id) {              
              var result = serviceAPI.editarServicesCustomers(this.crud.form);
            } else {
              var result = serviceAPI.agregarServicesCustomers(this.crud.form);
            }

            result.then((response) => {
              this.modal.form.active = false
              loader.hide()
              this.filterServicesCustomers()
              this.$awn.success("Datos guardados con éxito");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })             
      },   
      close() {
        this.$bvModal.msgBoxConfirm('¿Desea salir del alta de servicio a cliente?', {
          title: 'Salir Alta Servicio',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'secondary',
          okTitle: 'SALIR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: 'secondary',
          headerTextVariant: 'black',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {  
            this.modal.form.active = false
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })          
      },

      loadCustomers (object) {        
        if(object){
          this.crud.form.customer = object
          this.crud.form.customer_id = object.id    
          
         if(!this.crud.form.price_list_id) {
            if(object.price_list) {
              if(object.price_list.list) {              
                this.crud.form.price_list = object.price_list.list
                this.crud.form.price_list_id = object.price_list.list.id
              }
            }          
          }            
        } else {
          this.crud.form.customer = null
          this.crud.form.customer_id = 0
        }
      },
      loadPriceList (object) {
        if(object){
          this.crud.form.price_list = object
          this.crud.form.price_list_id = object.id             
        } else {
          this.crud.form.price_list = null
          this.crud.form.price_list_id = 0
        }
      },   

      servicesCustomersValidStepOne() {
        if(!this.crud.form.date_init) {
          this.$awn.alert("No se cargó la fecha de inicio");
          return false
        }

        if(!this.crud.form.name) {
          this.$awn.alert("No se cargó un nombre de referencia");
          return false
        }

        if(!this.crud.form.customer_id) {
          this.$awn.alert("No se cargó ningún cliente");
          return false
        }

        return true        
      }, 

      filterLoad() {
        this.filterLoadCustomers()

        this.restoreFilterStorage() 
      },
      filterLoadCustomers() {
        var result = serviceAPI.obtenerClientes()        
        result.then((response) => {
          var data = response.data    

          this.arr.filter.customers = []
          data.forEach(element => {            
            this.arr.filter.customers.push({ code: element.id, label: element.name })            
          });          
        })   
      },
      filterServicesCustomers(){   
        this.saveFilterStorage()     

        this.table.isBusy = true 
   
        var result = serviceAPI.filtrarServiceCustomers(this.filter)        
        result.then((response) => {          
          var data = response.data
          this.table.items = data
          this.arr.servicesCustomers = data

          if(this.table.rowSelected!=null) {
            this.table.items[this.table.rowSelected]._showDetails = true

            if(this.table.items[this.table.rowSelected].details) {
              this.tableServices.items = this.table.items[this.table.rowSelected].details
            }            
          }

          this.table.isBusy = false
        })
        .catch(error => {          
          this.table.isBusy = false          
          this.$awn.alert(Error.showError(error))
        });          
      },  
      
      openCancel(item) {
        this.crud.cancel.id = item.id
        this.crud.cancel.date_end = moment().format('YYYY-MM-DD')
        this.crud.cancel.reasons_canceled = ''
        
        this.modal.cancel.active = true
        this.modal.cancel.title = "Cancelar Servicio"
      },
      saveCancel() {
        this.$bvModal.msgBoxConfirm('¿Desea cancelar el servicio?', {
          title: 'Cancelar Servicios',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'danger',
          okTitle: 'ACEPTAR',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {      
            let loader = this.$loading.show();
            var result = serviceAPI.cancelarServicesCustomers(this.crud.cancel);

            result.then((response) => {      
              this.modal.cancel.active = false        
              loader.hide()
              this.filterServicesCustomers()
              this.$awn.success("Servicio Cancelado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }  
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })          
      },
      restoreServoceCancel(item) {
        this.$bvModal.msgBoxConfirm('¿Desea restaurar el servicio?', {
          title: 'Restaurar Servicios',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'info',
          okTitle: 'RESTAURAR',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'info',
          headerTextVariant: 'black',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {      
            let loader = this.$loading.show();            
            
            this.crud.cancel.id = item.id            
            var result = serviceAPI.restaurarServicesCustomers(this.crud.cancel);

            result.then((response) => {      
              this.modal.cancel.active = false        
              loader.hide()
              this.filterServicesCustomers()
              this.$awn.success("Servicio Restaurado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }  
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })          
      },   
      
      daysForRenewal(item) {        
        var date1 = moment();
        var date2 = moment(item.date_renewal);

        return date2.diff(date1, 'days') + 1;
      },
      daysVtoRenewal(item) {        
        var date1 = moment(item.date_renewal);
        var date2 = moment();

        return date2.diff(date1, 'days') + 1;
      },  
      
      goProject(item) {
        this.$router.push({ name: 'ProjectStaffDetailHoursProjects', params: { projectID: item.project_id } })                  
      },   
      
      // FILTROS SAVE
      saveFilterStorage() {
        Storage.setValue('filter_services_customers', JSON.stringify(this.filter))
      },
      restoreFilterStorage() {
        if(Storage.getValue('filter_services_customers')) {
          this.filter = JSON.parse(Storage.getValue('filter_services_customers'))
        }         
      },      
    }    
  }
</script>
<style scoped>
  .crud-services-customers-reasons-canceled {
    cursor: pointer;
  }
  .crud-services-vto-warning {
    background: goldenrod;
    font-weight: bold;
    color: white;
  }
  .crud-services-vto-success {
    background: darkgreen;
    font-weight: bold;
    color: white;
  }  
  .crud-services-vto-danger {
    background: darkred;
    font-weight: bold;
    color: white;
  }    
  .popover-observations {
    cursor: pointer;
  }  
</style>
<style scoped>
  .b-avatar {
    width: 25px;
    height: 25px;    
  }  
</style>